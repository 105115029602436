.my-select {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
  }
  
  .my-select:focus {
    outline: none;
    border-color: #007bff;
  }